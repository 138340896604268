import React from 'react';

const CardGrid = () => {
  return (
    <div style={styles.gridContainer}>
      <div style={styles.card}>
        <h5 style={styles.header}>Recruit365</h5>
        <p style={{padding:'10px'}}>Repo recruiting turnkey system to find great talent.</p>
        <a href="#" style={styles.link}>Connect with advisor</a>
        <div style={styles.actions}>
          <button style={styles.button}>Learn more</button>
          <button style={styles.butto}>Login</button>
        </div>
      </div>

      <div style={styles.card}>
        <h5 style={styles.header}>RepoWeb365</h5>
       <span style={{padding:'10px'}}>
       <p>Website Design, Programming and Development Services.</p>
        <a href="#" style={styles.link}>Get Started</a>
        <a href="#" style={styles.link}>Access Training</a>
       </span>
        <div style={styles.actions}>
          <button style={styles.button}>Learn more</button>
          <button style={styles.butto}>Login</button>
        </div>
      </div>

      <div style={styles.card}>
        <h5 style={styles.header}>BackgroundChecks365</h5>
        <span style={{padding:'10px'}}>
        <p>Affordable, ARA background check services.</p>
        <a href="#" style={styles.link}>Get Started</a>
        <a href="#" style={styles.link}>Access Training</a>
        </span>
        <div style={styles.actions}>
          <button style={styles.button}>Learn more</button>
          <button style={styles.butto}>Login</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    // maxWidth: '900px',
    padding:'1em',
    margin: '0 auto',
  },
  card: {
    border: '2px solid #203AAB',
    borderRadius: '22px',
    // padding: '15px',
    textAlign: 'center',
    // width: '250px', 
    height: '300px', // Fixed height for all cards
    display: 'flex', // To vertically center content
    flexDirection: 'column', // Align content in a column
    justifyContent: 'space-between', // Ensure equal spacing between elements
  },
  header: {
    color: '#203AAB',
    padding: '15px',

    borderBottom:'2px solid #203AAB',
    marginBottom: '10px',
  },
  link: {
    color: '#F7921E',
    display: 'block',
    marginBottom: '10px',
    textDecoration:'underline',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#F7921E',
    border: 'none',
    padding: '10px 2em',
    borderRadius: '4px 0px 2px  22px ',
    color: 'white',
    cursor: 'pointer',
  },
  butto: {
    backgroundColor: '#F7921E',
    border: 'none',
    padding: '10px 2em',
    borderRadius: '4px 0px 22px  0px ',
    color: 'white',
    cursor: 'pointer',
  },
};

export default CardGrid;
