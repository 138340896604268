import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS if not already included
import logoimg from "./img/BackgroundImage.png";

export default function SupportPage() {
  return (
    <div
      className='full-page'
      style={{
        padding: '1em',
        backgroundColor: '#203AAB',
        backgroundImage: `url(${logoimg})`, // Corrected backgroundImage syntax
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="row">
        {/* Left Section */}
        <div className="col-md-7" style={{ padding: '2em' }}>
          <h1 style={{ color: 'white' }}>Welcome back, Wayne!</h1>
          <div
            style={{
              border: '2px solid white',
              backgroundColor: 'white',
              padding: '2em',
              borderRadius: '22px',
              marginTop: '0.2em',
            }}
          >
            <p style={{ fontSize: '24px', color: '#000000',fontWeight:'bold' }}>
              How can we help you today, Wayne?
            </p>
            <textarea
              rows="5"
              style={{
                width: '100%',
                backgroundColor: '#F2F1F1',
                borderRadius: '5px',
                fontSize: '20px',
                padding: '10px',
              }}
              placeholder="Some things you can ask are: where can I find help with service calls, or where is the nearest repo worker?"
            />
            <p style={{ fontSize: '24px', color: '#000000', marginTop: '1em' ,fontWeight:'bold'}}>
              I wish I could
            </p>
            <textarea
              rows="5"
              style={{
                width: '100%',
                fontSize: '20px',
                backgroundColor: '#F2F1F1',
                borderRadius: '5px',
                padding: '10px',
              }}
              placeholder="This is your chance to test our AI while at the same time letting us know what we can do to make things better for you."
            />
          </div>
        </div>

        {/* Right Section - Support Form */}
        <div className="col-5">
          <div style={{ paddingTop: '2em' }}>
            <h1 style={{ color: 'white' }}>Customer Support</h1>
            <form>
              <div
                style={{
                  border: '2px solid white',
                  padding: '2em',
                  borderRadius: '22px',
                  backgroundColor: 'white',
                  marginTop: '0.2em',
                }}
              >
                {/* Name Section */}
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <label
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '5px',
                        display: 'block',
                        fontSize: '14px',
                      }}
                    >
                      Name:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="First"
                      style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#F2F1F1',
                        borderRadius: '4px',
                        border: '1px solid #F2F1F1',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <input
                      type="text"
                      placeholder="Last"
                      style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#F2F1F1',
                        borderRadius: '4px',
                        border: '1px solid #F2F1F1',
                        fontSize: '14px',
                        marginTop: '24px', // Align with first name input
                      }}
                    />
                  </div>
                </div>

                {/* How can we help you? */}
                <div style={{ marginBottom: '15px' }}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      display: 'block',
                      fontSize: '14px',
                    }}
                  >
                    How can we help you?<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="help" style={{ marginRight: '5px' }} />
                      Feature
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="help" style={{ marginRight: '5px' }} />
                      Bug
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="help" style={{ marginRight: '5px' }} />
                      Other:
                    </label>
                  </div>
                </div>

                {/* Priority */}
                <div style={{ marginBottom: '15px' }}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      display: 'block',
                      fontSize: '14px',
                    }}
                  >
                    Priority:<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="priority" style={{ marginRight: '5px' }} />
                      High
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="priority" style={{ marginRight: '5px' }} />
                      Medium
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="priority" style={{ marginRight: '5px' }} />
                      Low
                    </label>
                  </div>
                </div>

                {/* Operating System */}
                <div style={{ marginBottom: '15px' }}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      display: 'block',
                      fontSize: '14px',
                    }}
                  >
                    Operating System:<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="os" style={{ marginRight: '5px' }} />
                      Mac OS
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="os" style={{ marginRight: '5px' }} />
                      Linux
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', border: '1px solid #F2F1F1', backgroundColor: '#F2F1F1', padding: '2px 10px' }}>
                      <input type="radio" name="os" style={{ marginRight: '5px' }} />
                      Windows
                    </label>
                  </div>
                </div>

                {/* Additional details */}
                <div style={{ marginBottom: '15px' }}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '5px',
                      display: 'block',
                      fontSize: '14px',
                    }}
                  >
                    Additional details:
                  </label>
                  <textarea
                    style={{
                      width: '100%',
                      height: '100px',
                      padding: '10px',
                      borderRadius: '4px',
                      border: '1px solid #F2F1F1',
                      fontSize: '14px',
                      backgroundColor: '#F2F1F1'
                    }}
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className='text-center'>
                <button
                  type="submit"
                  style={{
                    backgroundColor: '#F7921E',
                    fontWeight: 'bold',
                    color: 'white',
                    fontSize: '16px',
                    padding: '15px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    width: '90%',
                  }}
                >
                  Submit Ticket
                </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
