
import './App.css';
import RepoOs from './Repo/RepoOs';

function App() {
  return (
    <div className="App">
 <RepoOs/>
    </div>
  );
}

export default App;
