import React from "react";

export default function DISCOVERMOREREPO365() {
  return (
    <div>
      <div
        style={{ fontSize: "22px", color: "white", backgroundColor: "#92A1E3",margin:'0px 15px',borderRadius:'5px' }}
        className="text-center"
      >
        DISCOVER MORE <b> REPO365 </b> SERVICES
      </div>

      <div className="row">
        <div className="col-6">
          <div style={{ padding: "2em 5em" }}>
            <div>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                RepoOne365
              </div>
              <div style={{ fontSize: "22px" }}>
                affordable, business apps to operate your company.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold",cursor:'pointer' }}>
                <span>Learn More &nbsp;</span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; FREE Trial &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                RepoLMS365
              </div>
              <div style={{ fontSize: "22px" }}>
                Train your employees & Track Progress.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold",cursor:'pointer' }}>
                <span>Learn More &nbsp;</span>

                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                RepoOS365
              </div>
              <div style={{ fontSize: "22px" }}>
                Run your Repo business with ai technology with less labor.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold" ,cursor:'pointer'}}>
                <span>Learn More &nbsp;</span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; Connect with an Advisor &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp; Access Training &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                RepoWeb365
              </div>
              <div style={{ fontSize: "22px" }}>
                Website Design, Programming and Development Services.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold",cursor:'pointer' }}>
                <span>Learn More &nbsp;</span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; Get Started &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp; Access Training &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
          </div>
        </div>

        {/* 2ndcolumn  */}

        <div className="col-6">
          <div style={{ padding: "2em 5em" }}>
            <div>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                DebtorVideo365
              </div>
              <div style={{ fontSize: "22px" }}>
                Reduce Debtor phone calls by up to 80%.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold",cursor:'pointer' }}>
                <span>Learn More &nbsp;</span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp;Get Started &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp;Access Training &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                ExecuGrowth365
              </div>
              <div style={{ fontSize: "22px" }}>
                ARA Leadership Development Assessment & Coaching
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold",cursor:'pointer' }}>
                <span>Learn More &nbsp;</span>

                <span style={{ color: "#203AAB" }}>|</span>
                <span>&nbsp;Get Started &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp;Access Training &nbsp; </span>
                <span style={{ color: "#203AAB" }}>|</span>

                <span>&nbsp; Login &nbsp;</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                MySiteInspection365
              </div>
              <div style={{ fontSize: "22px" }}>
                ARA Virtual Site Inspection Portal.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold" ,cursor:'pointer'}}>
                <span>Learn what you need to comply</span>
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#203AAB",
                  fontWeight: "bold",
                }}
              >
                ESGSaaS365
              </div>
              <div style={{ fontSize: "22px" }}>
                Real-time.ai SAAS Cloud Technology.
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold" ,cursor:'pointer'}}>
                <span>Connect with an ESG Advisor</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
