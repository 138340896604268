import React from "react";
import RepoOnelogo from "./img/RepoOnelogo 1.png";
import iconLogo from "./img/Group 43.png";
import CardGrid from "./CardGrid";
import RepoAnalytics from "./img/RepoAnalyticsWhite365logo 1.png";
import Rectangle from "./img/Rectangle 58.png";
import Recruit365 from "./img/Recruit365Whitelogo 1.png";
import RectangleB from "./img/Rectangle 67.png";
export default function QuickLinks() {
  return (
    <div>
      <div className="row" style={{ padding: "1em" }}>
        <div className="col-7">
          <div
            style={{
              margin: "20px",
              border: "2px solid #203AAB",
              borderRadius: "22px",
              padding: "2em",
            }}
          >
            <h3
              style={{
                color: "blue",
                fontSize: "16px",
                marginBottom: "20px",
                fontWeight: "bold",
              }}
            >
              Quick Links
            </h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
              }}
            >
              {Array(8)
                .fill("ARA Compliance")
                .map((text, index) => (
                  <button
                    key={index}
                    style={{
                      backgroundColor: "#F7921E",
                      color: "white",
                      border: "none",
                      padding: "10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {text}
                  </button>
                ))}
            </div>
            <a
              href="#"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginTop: "20px",
                display: "block",
              }}
            >
              customize links
            </a>
          </div>
          {/* 2nd component  */}
          <div style={{ padding: "1em" }}>
            <div
              style={{
                border: "2px solid #203AAB",
                borderRadius: "22px",
                padding: "2em",
              }}
            >
              <div style={{ color: "#203AAB", fontWeight: "bold" }}>
                <img src={RepoOnelogo} alt="logo" />
                <span> powered by Zoho</span>
              </div>
              <div className="mt-3">
                <img
                  src={iconLogo}
                  alt="icon"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
              <div style={{ color: "#F7921E", fontWeight: "bold" }}>
                <span>Customize apps &nbsp;</span>
                <span style={{ color: "blue" }}>|</span>
                <span>&nbsp; Start a FREE trial &nbsp;</span>
                <span style={{ color: "blue" }}> |</span>
                <span>&nbsp; Login</span>
              </div>
            </div>
          </div>

          {/* 3rd row */}
          <div>
            <CardGrid />
          </div>
        </div>
        <div className="col-5">
          {/* 1st component */}

          <div style={{ padding: "1em",height:'65vh' }}>
            <div
              style={{
                border: "1px solid blue",
                backgroundColor: "#203AAB",
                padding: "2em",
                borderRadius: "22px",
              }}
            >
              <div className="mb-2">
                <img src={RepoAnalytics} alt="logo" />
              </div>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  margin: "0em",
                }}
              >
                <img
                  src={Rectangle}
                  alt="whitespace"
                  style={{ width: "70%" }}
                />
              </div>
            </div>
          </div>
          {/* 2nd component */}
          <div style={{padding:'1em'}}>
          <div style={{ backgroundColor: "#203AAB" ,padding:'2.4em 2.4em 8em 2.4em ',borderRadius:'22px',marginTop:'2em',}}>
            <div>
              <img src={Recruit365} alt="logo" />
            </div>
            <div>
              <div style={{display:'flex',justifyContent:'space-between',marginTop:'2em',}}>
                <img src={RectangleB} alt="" style={{}} />
            
              <img src={RectangleB} alt="" />

              </div>
             
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
