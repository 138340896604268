import React from "react";
import logo from "./img/RepoOSWhite365logo.png";
import SupportPage from "./SupportPage";
import QuickLinks from "./QuickLinks";
import DISCOVERMOREREPO365 from "./DISCOVERMOREREPO365";
import FooterComponent from "./Footer";

export default function RepoOs() {
  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#203AAB",
        padding: "1em 2em",
        color: "white",
      }}
    >
      {/* Logo Section */}
      <div style={{paddingTop:'1em'}}>
        <img src={logo} alt="RepoOS Logo" style={{ width: "90%" }} />
      </div>

      {/* Text Section */}
      <div style={{ textAlign: "right" }}>
        <div style={{ fontSize: "16px", marginBottom: "8px" }}>
          <a href="#" style={{ color: "white", textDecoration: "underline" }}>
            Sign in
          </a>
        </div>
        <div style={{ fontSize: "18px",fontWeight:'bold' }}>
          Your business. Your way. Right now.
        </div>
      </div>
    </div>
    
 <SupportPage/>
 <div style={{padding:'2em'}}>
  <QuickLinks/>
 </div>
 <div >
 <DISCOVERMOREREPO365/>
 </div>
 <div>
  <FooterComponent/>
 </div>
    
    </>
  );
}
